<template>
  <div class="page-content">
    <slot />
  </div>
</template>

<style lang="scss">
@use "~/assets/sass/tools";

  .page-content {

  }
</style>
